import { admin2 } from './instance'

export const CreateDrFootSetting = async ({
  shopId,
  enabled,
  config,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/shopCustomModule/drFoot`,
    data: {
      enabled,
      config,
    },
  })
}

export const UpdateDrFootSetting = async ({
  shopId,
  enabled,
  config,
}) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/shopCustomModule/drFoot`,
    data: {
      enabled,
      config,
    },
  })
}
