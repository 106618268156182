<template>
  <div class="member-level-sync-settings section-block">
    <SectionTitle title="會員等級同步設定" hideBtn />
    <BaseElForm label-position="left" label-width="220px">
      <BaseElFormItem label="與瑞乘 POS 上之會員等級同步">
        <!-- el-switch -->
        <BaseElSwitch
          v-model="formData.enable"
          active-text="開啟"
          inactive-text="關閉"
          @change="onChange"
        />
      </BaseElFormItem>

      <div class="flex items-center gap-[12px]">
        <BaseElButton
          type="primary"
          :disabled="!formData.enable"
          :loading="loading.sync"
          @click="syncing"
        >
          點擊以同步
        </BaseElButton>
        <p class="text-danger">
          注意：點擊同步按紐前，請務必確認歐巴後台設定之會員等級名稱與瑞乘 POS
          上之等級名稱完全一致，若不一致將會造成同步出錯。
        </p>
      </div>
    </BaseElForm>
  </div>
</template>

<script>
import { defineComponent, reactive, watch } from 'vue'
import { UpdatePospalConfig, SyncPospalMemberLevel } from '@/api/pospal'
import { get, omit } from 'lodash'
import { useShop } from '@/use/shop'
import notifyMessage from '@/config/notifyMessage'

export default defineComponent({
  name: 'MemberLevelSyncSettings',
  props: {
    configData: { type: Object, default: () => ({}) },
  },
  setup(props, { emit }) {
    const { shopId } = useShop()
    const formData = reactive({
      enable: false,
    })

    const loading = reactive({
      sync: false,
    })

    const onChange = async (toggle) => {
      const [, errMsg] = await UpdatePospalConfig({
        shopId: shopId.value,
        config: {
          ...omit(get(props.configData, 'config', {}), ['shopId', 'syncMemberLevel']),
          syncMemberLevel: toggle,
        },
      })

      if (errMsg) {
        window.$message.error(errMsg)
        formData.enable = false
        return
      }
      window.$message.success(notifyMessage.updateSuccess)
    }

    const syncing = async () => {
      if (loading.sync) return
      loading.sync = true
      const [, errMsg] = await SyncPospalMemberLevel({ shopId: shopId.value })
      loading.sync = false
      if (errMsg) {
        window.$message.error(errMsg)
        return
      }
      window.$message.success('同步成功')
    }

    watch(
      () => props.configData,
      (val) => {
        formData.enable = get(props.configData, 'config.syncMemberLevel', false)
      },
    )

    return { formData, loading, syncing, onChange }
  },
})
</script>

<style lang="postcss" scoped></style>
