import { noEmptyRules } from '@/validation'

export const opentixApiFieldsConfig = {
  opentixXApiId: null,
  opentixXApiKey: null,
}
export const opentixApiFieldsRulesConfig = {
  opentixXApiId: [noEmptyRules()],
  opentixXApiKey: [noEmptyRules()],
}
export const opentixSalesTimeConfig = {
  createTime: { label: '購票時間', value: 'createTime' },
  eventEndTime: { label: '場次結束時間', value: 'eventEndTime' },
}
