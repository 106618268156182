import { noEmptyRules } from '@/validation'

export const weibyApiFieldsConfig = {
  weibyApiHost: null,
  weibyApiKey: null,
  weibyApiStoreId: null,
  weibyApiVendorId: null,
}
export const weibyApiFieldsRulesConfig = {
  weibyApiHost: [noEmptyRules()],
  weibyApiKey: [noEmptyRules()],
  weibyApiStoreId: [noEmptyRules()],
  weibyApiVendorId: [noEmptyRules()],
}
