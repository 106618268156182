import { admin2 } from '@/api/instance'

export const CreateDudooConfig = async ({
  shopId,
  enabled,
  config,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/shopCustomModule/dudoo`,
    data: {
      enabled,
      config,
    },
  })
}

export const UpdateDudooConfig = async ({
  shopId,
  enabled,
  config,
}) => {
  return admin2({
    method: 'PUT',
    url: `/${shopId}/shopCustomModule/dudoo`,
    data: {
      enabled,
      config,
    },
  })
}

export const UpsertDudooShopId = async ({
  shopId,
  branchId,
  dudooShopId,
}) => {
  return admin2({
    method: 'PUT',
    url: `/${shopId}/shopCustomModule/upsertDudooShopId`,
    data: {
      branchId,
      dudooShopId,
    },
  })
}
