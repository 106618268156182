import { integerRules, isDigitRules, noEmptyRules } from '@/validation'

export const pospalApiFieldsConfig = {
  areaId: null,
  host: null,
  appId: null,
  appKey: null,
  memberPrefix: null,
  // enableDisplayTransaction: false,
  // enableDisplayBalance: false,
}
export const pospalApiFieldsRulesConfig = {
  areaId: [isDigitRules(false), integerRules(false)],
  host: [noEmptyRules()],
  appId: [noEmptyRules()],
  appKey: [noEmptyRules()],
  // memberPrefix: [noEmptyRules()],
  // enableDisplayTransaction: false,
  // enableDisplayBalance: false,
}
