import { noEmptyRules } from '@/validation'

export const drFootApiFieldsConfig = {
  drFootApiHost: null,
  drFootApiMerchantId: null,
  drFootShopId: null,
}
export const drFootApiFieldsRulesConfig = {
  drFootApiHost: [noEmptyRules()],
  drFootApiMerchantId: [noEmptyRules()],
  drFootShopId: [noEmptyRules()],
}
