<template>
  <div class="external-module-settings section-block">
    <SectionTitle :title="`${title}串接設定`" @edit="modal.edit = true" />
    <BaseElForm label-position="left" label-width="220px">
      <BaseElFormItem v-for="field in displayData" :key="field.label" :label="field.label">
        {{ field.value }}
      </BaseElFormItem>
    </BaseElForm>

    <DrFootModuleSettingsEditModal
      v-if="modal.edit"
      :title="title"
      :configData="configData"
      @close="modal.edit = false"
      @refresh="refresh"
    />
  </div>
</template>

<script>
import { defineComponent, ref, computed, reactive, onMounted } from 'vue'
import SectionTitle from '@/components/Title/SectionTitle.vue'
import DrFootModuleSettingsEditModal from './DrFootModuleSettingsEditModal.vue'
import { get, find } from 'lodash'
import { GetCustomModule } from '@/api/customModule'
import { useShop } from '@/use/shop'

export default defineComponent({
  name: 'DrFootModuleSettings',
  components: { SectionTitle, DrFootModuleSettingsEditModal },
  props: {
  },
  emits: ['refresh'],
  setup (props) {
    const { shopId } = useShop()
    const loading = ref(false)
    const configData = ref({})
    const modal = reactive({
      edit: false,
    })
    const title = ref('Dr.Foot 健康雲')
    const displayData = computed(() => {
      const config = configData.value
      return [
        { label: `啟用 ${title.value}串接`, value: get(config, '[0].enabled') ? '開啟' : '關閉' },
        { label: 'API Host', value: get(config, '[0].config.drFootApiHost') || '-' },
        { label: 'merchantId', value: get(config, '[0].config.drFootApiMerchantId') || '-' },
        { label: '門市代碼', value: get(config, '[0].config.drFootShopId') || '-' },
      ]
    })

    const getCustomModule = async () => {
      const [res, err] = await GetCustomModule({
        shopId: shopId.value,
        type: 'drFoot',
      })
      if (err) throw err
      configData.value = res
    }

    const refresh = async () => {
      try {
        loading.value = true
        await getCustomModule()
        loading.value = false
      } catch (error) {
        window.$message.error(error)
      }
    }

    onMounted(async () => {
      refresh()
    })

    return {
      modal,
      loading,
      displayData,
      configData,
      refresh,
      title,
    }
  },
})
</script>

<style lang="postcss" scoped>

</style>
