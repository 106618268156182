<template>
  <BaseDialog
    :title="`編輯${title}串接模組設定`"
    :btn1Loading="loading"
    width="610px"
    @close="$emit('close')"
    @confirm="onConfirm"
  >
    <BaseElForm ref="formRef" :model="formData" :rules="formRules" label-position="top">
      <BaseElFormItem prop="enable">
        <template slot="label">
          <FormItemTooltipLabel :label="`啟用${title}串接設定`" :tooltipWidth="200">
            第一次開啟後的1:00AM將會開始進行會員同步
          </FormItemTooltipLabel>
        </template>
        <BaseElSwitch v-model="formData.enable" inactive-text="關閉" active-text="開啟" />
      </BaseElFormItem>
      <PospalApiForm :formData.sync="formData" :disableFields="formData.enable" />
    </BaseElForm>
  </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
// import PospalApiForm from '@/components/ThirdParty/Pospal/PospalApiForm.vue'
import PospalApiForm from '../PospalApiForm.vue'
import { useBaseForm } from '@/use/useForm'
import { computed, defineComponent, onMounted, set } from 'vue'
import { useShop } from '@/use/shop'
import { get, omit, find, omitBy } from 'lodash'
import { CreatePospalConfig, UpdatePospalConfig } from '@/api/pospal'
// config
import { customModuleOptionsConfig } from '@/config/customModule'
import { pospalApiFieldsConfig, pospalApiFieldsRulesConfig } from '@/config/pospal'
import { usePermissions } from '@/use/permissions'

export default defineComponent({
  name: 'PospalModuleSettingsEditModal',
  components: { BaseDialog, FormItemTooltipLabel, PospalApiForm },
  props: {
    configData: { type: Array, default: () => [] },
    title: { type: String, default: '' },
  },
  emits: ['refresh', 'close'],
  setup (props, { emit }) {
    const { shopId } = useShop()
    const { checkAction } = usePermissions()
    const { initFormData, formData, formRef, onSubmit, loading } = useBaseForm()
    initFormData({
      enable: false,
      ...pospalApiFieldsConfig,
    })
    const formRules = {
      ...pospalApiFieldsRulesConfig,
    }

    const displayProviderOptions = computed(() => {
      const omitList = []
      if (!checkAction('admin.shopCustomModule.createPosPal')) omitList.push('pospal')
      return omit(customModuleOptionsConfig, omitList)
    })

    const submitData = computed(() => {
      const omitList = ['provider']
      const data = { ...formData }
      // data.memberPrefix = data.memberPrefix.trim()
      // console.log('data.memberPrefix', data.memberPrefix)
      if (data.memberPrefix) data.memberPrefix = data.memberPrefix.trim()
      // console.log('data.memberPrefix', data.memberPrefix)
      // console.log('data.memberPrefix', data.memberPrefix)
      if (!data.memberPrefix) {
        // omitList.push('memberPrefix')
        data.memberPrefix = ''
      }
      return omit(data, omitList)
    })

    const handleSubmit = async (data) => {
      const config = props.configData
      const pospalConfig = find(config, { type: 'posPal' })
      let apiMethod = UpdatePospalConfig
      if (!pospalConfig) apiMethod = CreatePospalConfig
      const [res, err] = await apiMethod({
        shopId: shopId.value,
        enabled: data.enable,
        config: {
          ...data,
          syncMemberLevel: false,
        },
      })
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('更新成功！')
      emit('refresh')
      emit('close')
    }

    const syncData = () => {
      const config = props.configData
      const pospalConfig = find(config, { type: 'posPal' })
      if (!pospalConfig) return
      formData.enable = get(pospalConfig, 'config.enable')
      // formData.provider = 'pospal'
      for (const key in pospalApiFieldsConfig) {
        formData[key] = get(pospalConfig, `config.${key}`)
      }
    }

    const onConfirm = async () => {
      await onSubmit(handleSubmit, submitData.value)
    }

    onMounted(() => {
      syncData()
    })
    return {
      formData,
      formRef,
      formRules,
      onConfirm,
      loading,
      customModuleOptionsConfig,
      displayProviderOptions,
    }
  },
})
</script>

<style lang="postcss" scoped></style>
