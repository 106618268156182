<template>
  <div class="external-module-parameters">
    <PageTitle
      title="第三方串接模組設定"
      icon="chevron_left"
      hideBtn
      style="margin-bottom: 64px"
      @iconClick="$router.push({ name: 'Parameters' })"
    />

    <div class="flex flex-col" style="gap: 24px">
      <!-- <ExternalModuleSettings /> -->
      <DudooModuleSettings v-if="useDudoo" @getConfig="setConfigData('Dudoo', $event)" />
      <PospalModuleSettings v-if="usePospal" @getConfig="setConfigData('posPal', $event)" />
      <MemberLevelSyncSettings v-if="usePospal" :configData="configData['posPal']" />
      <PospalSyncingSettingsBlock v-if="usePospal" :configData="configData['posPal']" />
      <WeibyModuleSettings v-if="useWeiby" />
      <OpentixModuleSettings v-if="useCouponOpentix" />
      <TWRoctModuleSettings v-if="useTWRorct" />
      <DrFootModuleSettings v-if="useDrFoot" />
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, reactive, set } from 'vue'
import PageTitle from '@/components/Title/PageTitle'
// import ExternalModuleSettings from './components/ExternalModuleSettings/ExternalModuleSettings.vue'
import DudooModuleSettings from './components/DudooModuleSettings/DudooModuleSettings.vue'
import PospalModuleSettings from './components/PospalModuleSettings/PospalModuleSettings.vue'
import MemberLevelSyncSettings from './components/MemberLevelSyncSettings.vue'
import WeibyModuleSettings from './components/PospalModuleSettings/WeibyModuleSettings.vue'
import OpentixModuleSettings from './components/PospalModuleSettings/OpentixModuleSettings.vue'
import TWRoctModuleSettings from './components/TWRoctModuleSettings.vue'
import PospalSyncingSettingsBlock from './components/PospalSyncingSettingsBlock.vue'
import DrFootModuleSettings from './components/DrFootModuleSettings/DrFootModuleSettings.vue'
import { usePermissions } from '@/use/permissions'

export default defineComponent({
  name: 'ExternalIntegrateParameters',
  components: {
    PageTitle,
    // ExternalModuleSettings,
    DudooModuleSettings,
    PospalModuleSettings,
    MemberLevelSyncSettings,
    WeibyModuleSettings,
    OpentixModuleSettings,
    TWRoctModuleSettings,
    PospalSyncingSettingsBlock,
    DrFootModuleSettings,
  },
  setup (props) {
    const { checkAction } = usePermissions()

    const configData = reactive({
      posPal: {},
    })

    const usePospal = computed(() => checkAction('admin.shopCustomModule.posPalClientConfig'))
    const useWeiby = computed(() => checkAction('adminView.shopCustomModule.weibyShopCustomModulePage'))
    const useCouponOpentix = computed(() => checkAction('admin.couponOpentix.page'))
    const useTWRorct = computed(() => checkAction('admin.shopCustomModule.createTwroctClient'))
    const useDrFoot = computed(() => checkAction('admin.shopCustomModule.updateDrFootClient'))
    const useDudoo = computed(() => checkAction('admin.shopCustomModule.updateDudooClient'))

    const setConfigData = (type, data) => {
      set(configData, type, data)
    }

    return {
      usePospal,
      useWeiby,
      useCouponOpentix,
      useTWRorct,
      useDudoo,
      configData,
      setConfigData,
      useDrFoot,
    }
  },
})
</script>

<style scoped lang="postcss">
.parameters-category-list {
  @apply grid grid-cols-3 gap-[44px];
}

@media screen and (max-width: 1200px) {
  .parameters-category-list {
    @apply grid grid-cols-2;
  }
}
</style>
