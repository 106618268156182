import { admin2 } from '@/api/instance'

export const CreateWeibyConfig = async ({
  shopId,
  enabled,
  config,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/shopCustomModule/weiby`,
    data: {
      enabled,
      config,
    },
  })
}

export const UpdateWeibyConfig = async ({
  shopId,
  enabled,
  config,
}) => {
  return admin2({
    method: 'PUT',
    url: `/${shopId}/shopCustomModule/weiby`,
    data: {
      enabled,
      config,
    },
  })
}
