import { admin2 } from './instance'

// 新增 opentix 串接
export const CreateTWRoctClient = async ({
  shopId,
  enabled,
  config,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/shopCustomModule/twroct`,
    data: {
      enabled,
      config,
    },
  })
}

// 更新 opentix 串接
export const UpdateTWRoctClient = async ({
  shopId,
  enabled,
  config,
}) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/shopCustomModule/twroct`,
    data: {
      enabled,
      config,
    },
  })
}
