<template>
  <BaseDialog :title="`編輯${title}串接模組設定`" :btn1Loading="loading" width="610px" @close="$emit('close')" @confirm="onConfirm">
    <BaseElForm ref="formRef" :model="formData" :rules="formRules" label-position="top">
      <BaseElFormItem prop="enabled" :label="`啟用${title}串接設定`">
        <BaseElSwitch
          v-model="formData.enabled"
          inactive-text="關閉"
          active-text="開啟"
        />
      </BaseElFormItem>
      <OpentixApiForm :formData.sync="formData" :disableFields="formData.enabled" />
    </BaseElForm>
  </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import OpentixApiForm from '../OpentixApiForm.vue'
import { useBaseForm } from '@/use/useForm'
import { noEmptyRules } from '@/validation'
import { computed, defineComponent, onMounted, set } from 'vue'
import { useShop } from '@/use/shop'
import { get, omit, find, omitBy } from 'lodash'
import { CreateOpentixClient, UpdateOpentixClient } from '@/api/opentix'
// config
import { customModuleOptionsConfig } from '@/config/customModule'
import { opentixApiFieldsConfig, opentixApiFieldsRulesConfig } from '@/config/opentix'
import { usePermissions } from '@/use/permissions'

export default defineComponent({
  name: 'OpentixModuleSettingsEditModal',
  components: { BaseDialog, OpentixApiForm },
  props: {
    configData: { type: Array, default: () => [] },
    title: { type: String, default: '' },
  },
  emits: ['refresh', 'close'],
  setup (props, { emit }) {
    const { shopId } = useShop()
    const { checkAction } = usePermissions()
    const { initFormData, formData, formRef, onSubmit, loading } = useBaseForm()
    initFormData({
      enabled: false,
      ...opentixApiFieldsConfig,
    })
    const formRules = {
      ...opentixApiFieldsRulesConfig,
    }

    const submitData = computed(() => {
      // const omitList = ['provider']
      const data = { ...formData }
      // // data.memberPrefix = data.memberPrefix.trim()
      // // console.log('data.memberPrefix', data.memberPrefix)
      // if (data.memberPrefix) data.memberPrefix = data.memberPrefix.trim()
      // // console.log('data.memberPrefix', data.memberPrefix)
      // // console.log('data.memberPrefix', data.memberPrefix)
      // if (!data.memberPrefix) {
      //   // omitList.push('memberPrefix')
      //   data.memberPrefix = ''
      // }
      // return omit(data, omitList)
      return data
    })

    const handleSubmit = async (data) => {
      const config = props.configData
      const openTixConfig = find(config, { type: 'opentix' })
      let apiMethod = UpdateOpentixClient
      if (!openTixConfig) apiMethod = CreateOpentixClient
      const [res, err] = await apiMethod({
        shopId: shopId.value,
        enabled: data.enabled,
        config: { ...data, enabled: undefined },
      })
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('更新成功！')
      emit('refresh')
      emit('close')
    }

    const syncData = () => {
      const config = props.configData
      const openTixConfig = find(config, { type: 'opentix' })
      if (!openTixConfig) return
      formData.enabled = get(openTixConfig, 'enabled')
      // formData.provider = 'pospal'
      for (const key in opentixApiFieldsConfig) {
        formData[key] = get(openTixConfig, `config.${key}`)
      }
    }

    const onConfirm = async () => {
      await onSubmit(handleSubmit, submitData.value)
    }

    onMounted(() => {
      syncData()
    })
    return { formData, formRef, formRules, onConfirm, loading, customModuleOptionsConfig }
  },
})
</script>

<style lang="postcss" scoped>

</style>
